export const actions = {
  LOAD_LEADS_STATISTICS: 'LOAD_LEADS_STATISTICS',
}

export const mutations = {
  SET_RAW_ANALYTICS_DATA: 'SET_RAW_ANALYTICS_DATA',
  SET_IS_LOADING: 'SET_IS_LOADING',

}

export const getters = {
  IS_LOADING: 'IS_LOADING',
  LEADS_STATISTICS_REPORT: 'LEADS_STATISTICS_REPORT',
}
