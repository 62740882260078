<template>
  <div class="analytics">
    <div class="analytics__header">
      <div class="analytics__query">
        <list-params-period
          class="analytics__query-period"
          :start-date.sync="query.from"
          :end-date.sync="query.to"
          show-icon
          auto-close
        />

        <div class="analytics__query-group">
          <label class="analytics__query-group-label">
            {{ $t('GROUP_BY') }}
          </label>
          <div class="analytics__query-group-btns">
            <ui-button
              class="analytics__query-group-btn"
              v-for="period in groupPeriods"
              :key="period.value"
              @click="query.groupByPeriods = period.value"
              :fill="query.groupByPeriods === period.value ? 'solid' : 'frame'"
            >
              {{ period.label }}
            </ui-button>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="hasData"
      class="analytics__content"
      :class="{ 'analytics__content_loading': isLoading }">
      <div
        class="analytics__report"
        v-for="chunk in leadStatisticReport.report"
        :key="chunk.rawDate"
      >
        <h3 class="analytics__report-title">
          {{ $fd(chunk.date, dateFormat) }}
        </h3>
        <div class="analytics__report-data">
          <div class="analytics__metric">
            <div
              class="analytics__metric-val  analytics__metric-val_big"
            >
              <h4 class="analytics__metric-val-text">
                {{ $t('LEADS_CREATED') }}
              </h4>
              <div
                class="analytics__metric-val-number
                analytics__metric-val-number_waiting"
                :class="{
                  'analytics__metric-val-number_zero': !chunk.leadsCreated.total
                }">
                {{ chunk.leadsCreated.total }}
              </div>
            </div>

            <div class="analytics__metric-ch">
              <div
                v-for="(label, k) in channels"
                :key="k"
                class="analytics__metric-ch-item"
                :aria-label="label"
                role="tooltip"
                data-microtip-position="bottom"
                data-microtip-size="small"
              >
                <channel-icon
                  :channel="k"
                  :class="{'analytics__metric-ch-item-icon_zero':
                    !chunk.leadsCreated.perChannel[k]}"
                  class="analytics__metric-ch-item-icon"
                />
                <div
                  class="analytics__metric-ch-item-number"
                  :class="{ 'analytics__metric-ch-item-number_zero':
                    !chunk.leadsCreated.perChannel[k] }">
                  {{ chunk.leadsCreated.perChannel[k] }}
                </div>
              </div>
            </div>

            <div class="analytics__metric-val analytics__metric-val_inline">
              <h4 class="analytics__metric-val-text">
                {{ $t('LEADS_FROM_ON_HOLD') }}
              </h4>
              <div
                :class="{
                  'analytics__metric-val-number_zero':
                    !chunk.leadsFromOnHold.total
                }"
                class="analytics__metric-val-number
                       analytics__metric-val-number_on-hold"
              >
                {{ chunk.leadsFromOnHold.total }}
              </div>
            </div>
            <div class="analytics__metric-val analytics__metric-val_inline">
              <h4 class="analytics__metric-val-text">
                {{ $t('LEADS_FROM_ARCHIVE') }}
              </h4>
              <div
                :class="{
                  'analytics__metric-val-number_zero':
                    !chunk.leadsFromArchive.total
                }"
                class="analytics__metric-val-number
                       analytics__metric-val-number_archived">
                {{ chunk.leadsFromArchive.total }}
              </div>
            </div>
          </div>

          <div class="analytics__metric">
            <div class="analytics__metric-val">
              <h4 class="analytics__metric-val-text">
                {{ $t('LEADS_TO_ON_HOLD') }}
              </h4>
              <div
                :class="{
                  'analytics__metric-val-number_zero':
                    !chunk.leadsToOnHold.total
                }"
                class="analytics__metric-val-number
                       analytics__metric-val-number_on-hold"
              >
                {{ chunk.leadsToOnHold.total }}
              </div>
            </div>
            <div class="analytics__metric-val">
              <h4 class="analytics__metric-val-text">
                {{ $t('LEADS_TO_ARCHIVE') }}
              </h4>
              <div
                :class="{
                  'analytics__metric-val-number_zero':
                    !chunk.leadsToArchive.total
                }"
                class="analytics__metric-val-number
                       analytics__metric-val-number_archived">
                {{ chunk.leadsToArchive.total }}
              </div>
            </div>
          </div>

          <div class=" analytics__metric-brokers">
            <h4 class="analytics__metric-brokers-title">
              {{ $t('LEADS_ASSIGNED') }}
            </h4>
            <div
              class="analytics__metric-brokers-content"
              v-if="chunk.leadsToAssignedPerBorker.length">
              <div
                class="analytics__metric-broker"
                v-for="b in chunk.leadsToAssignedPerBorker"
                :key="b.brokerId"
                :aria-label="b.fullName"
                role="tooltip"
                data-microtip-position="bottom"
                data-microtip-size="small"
              >
                <ui-avatar
                  class="analytics__metric-broker-avatar"
                  :src="b.avatarUrl"
                  :alt="b.fullName" />
                <div class="analytics__metric-broker-counter">
                  {{ b.count }}
                </div>
              </div>
            </div>
            <div
              v-else
              class=" analytics__metric-brokers-ph">
              {{ $t('LEADS_ASSIGNED_PH') }}
            </div>
          </div>

          <div class="analytics__metric analytics__metric_summary">
            <div class="analytics__metric-val analytics__metric-val_big">
              <h4 class="analytics__metric-val-text">
                {{ $t('LEADS_CONVERSION_SUMMARY') }}
              </h4>
              <div
                :class="{
                  'analytics__metric-val-number_zero':
                    !chunk.leadConversionSummary.value
                }"
                class="analytics__metric-val-number
                       analytics__metric-val-number_waiting"
              >
                {{ chunk.leadConversionSummary.percent }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="analytics__content-ph"
    >
      {{ $t('NO_DATA_TO_DISPLAY') }}
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { getters, actions } from './store/types'
import { ListParamsPeriod } from 'Common/ListParams'
import { UiButton, UiAvatar } from '@shelf.network/ui-kit'
import { ReportLeadsStatistics } from 'Models/ReportLeadsStatistics'
import { Lead } from 'Models/Lead'
import ChannelIcon from 'Common/ChannelIcon'
import isEmpty from 'lodash/isEmpty'
import { DEFAULT_DAYS_BACK, DEFAULT_GROUP_PERIOD } from './store/constants'

const reportPeriods = ReportLeadsStatistics.periodEnums

export default {
  name: 'analytics',
  components: { ListParamsPeriod, UiButton, ChannelIcon, UiAvatar },

  data () {
    return {
      query: {
        from: '',
        to: '',
        groupByPeriods: DEFAULT_GROUP_PERIOD
      }
    }
  },
  computed: {
    ...mapGetters({
      isLoading: `ui/analytics/${getters.IS_LOADING}`,
      leadStatisticReport: `ui/analytics/${getters.LEADS_STATISTICS_REPORT}`,
    }),
    dateFormat () {
      switch (this.query.groupByPeriods) {
        case reportPeriods.DAY:
          return this.$t('DATE_FORMATS.WEEKDAY_MONTH_DAY_YEAR_SHORT')
        case reportPeriods.MONTH:
          return this.$t('DATE_FORMATS.MONTH_YEAR_SHORT')
        default:
          return this.$t('DATE_FORMATS.MONTH_DAY_YEAR_SHORT')
      }
    },
    channels () {
      return {
        [Lead.channelsEnum.phone]: this.$t('COMMON.LEAD_CHANNELS.PHONE'),
        [Lead.channelsEnum.email]: this.$t('COMMON.LEAD_CHANNELS.EMAIL'),
        [Lead.channelsEnum.website]: this.$t('COMMON.LEAD_CHANNELS.WEBSITE'),
        [Lead.channelsEnum.carfax]: this.$t('COMMON.LEAD_CHANNELS.CARFAX'),
        [Lead.channelsEnum.facebook]: this.$t('COMMON.LEAD_CHANNELS.FACEBOOK'),
        [Lead.channelsEnum.telegram]: this.$t('COMMON.LEAD_CHANNELS.TELEGRAM'),
        [Lead.channelsEnum.facebookWeb]: this.$t('COMMON.LEAD_CHANNELS.FACEBOOK_WEB'),
        [Lead.channelsEnum.rajdeba]: this.$t('COMMON.LEAD_CHANNELS.RAJDEBA'),
      }
    },
    hasData () {
      return !isEmpty(this.leadStatisticReport.report)
    },
    groupPeriods () {
      return {
        [reportPeriods.DAY]: {
          label: this.$t('PERIOD_DAY'),
          value: reportPeriods.DAY
        },
        [reportPeriods.WEEK]: {
          label: this.$t('PERIOD_WEEK'),
          value: reportPeriods.WEEK
        },
        [reportPeriods.MONTH]: {
          label: this.$t('PERIOD_MONTH'),
          value: reportPeriods.MONTH
        },
      }
    },
  },
  watch: {
    query: {
      deep: true,
      immediate:
        true,
      handler () {
        if (!this.query.from || this.query.from > (new Date())) {
          const from = new Date()
          from.setDate(from.getDate() - DEFAULT_DAYS_BACK)
          this.query.from = from.toISOString()
          return
        }
        if (!this.query.to || this.query.to > (new Date())) {
          this.query.to = (new Date()).toISOString()
          return
        }

        this.loadLeadsStatistics(this.query)
      }
    }
  },
  methods: {
    ...mapActions({
      loadLeadsStatistics: `ui/analytics/${actions.LOAD_LEADS_STATISTICS}`,
    }),
  },
}
</script>

<style scoped lang="scss">
.analytics {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__metric {
    margin-right: 4em;

    &_summary {
      margin-left: auto;
      text-align: right;
    }

    &-val {
      &-number {
        font-size: 2em;
        margin-bottom: 1.12em;

        &_waiting {
          color: $color-ui-waiting;
        }

        &_on-hold {
          color: $color-ui-looking;
        }

        &_archived {
          color: $color-ui-offered;
        }

        &_zero {
          color: $color-grey;
        }
      }

      &-text {
        font-size: 0.9em;
      }

      &_big &-text {
        margin-bottom: 0.5em;
      }

      &_big &-number {
        font-size: 3.2em;
        font-weight: 400;
        margin-bottom: 0.25em;
      }

      &_inline {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0.5em 0;
      }

      &_inline &-text {
        margin: 0 1.5em 0 0;
        font-size: 1em;
      }

      &_inline &-number {
        min-width: 2em;
        margin: 0 0.25em;
        font-size: 1em;
        text-align: center;
      }
    }

    &-ch {
      display: flex;
      flex-direction: row;
      margin: 0.5em 0 2em 0;
      justify-content: space-between;

      &-item {
        text-align: center;
        width: 2em;
        margin: 0 0.25em;

        &-number {
          &_zero {
            color: $color-grey;
          }
        }

        &-icon {
          margin: 0.25em auto;
          display: block;
          font-size: 1.24em;

          &_zero {
            filter: saturate(0);
            opacity: 0.25;
          }
        }
      }
    }

    &-brokers {
      margin-right: 4em;

      &-title {
        margin-bottom: 2em;
        font-size: 0.9em;
      }

      &-ph {
        color: $color-grey;
      }
    }

    &-broker {
      display: inline-block;
      position: relative;
      margin: 0 1.1em 1.1em 0;

      &-avatar {
        font-size: 2.8em;
      }

      &-counter {
        position: absolute;
        min-width: 2em;
        min-height: 2em;
        background: $color-sys-success;
        color: $color-white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 1em;
        padding: 0.1em;
        font-size: 0.74em;
        right: 0;
        bottom: 0;
      }
    }
  }

  &__report {
    &-title {
      font-size: 1.2em;
      font-weight: bolder;
    }

    &-data {
      margin: 0.7em 0 1em 0;
      padding: 0.7em 0 1.5em 0;
      display: flex;
      justify-items: stretch;
      border-top: 1px solid silver;
    }
  }

  &__header {
    overflow-y: hidden;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-bottom: 100vh;
    margin-bottom: -100vh;
  }

  &__query {
    background-color: #f0f2f5;
    display: flex;
    width: auto;
    min-width: 100%;
    align-items: center;
    padding: 1em 2em;
    white-space: nowrap;

    @include respond-below(sm) {
      display: grid;
      row-gap: 1.6em;
      padding: 1em;
    }

    &-period {
      display: block;
      min-width: 30vw;
      margin-right: 3em;

      & /deep/ .ui-button__button[fill=frame] {
        background: transparent;
      }
    }

    &-group {
      &-label {
        display: block;
        font-size: 0.85em;
        margin-bottom: 0.33em;
        line-height: 1.33;
      }

      &-btn {
        margin-right: 1em;

        & /deep/ .ui-button__button[fill=frame] {
          background: transparent;
        }
      }
    }
  }

  &__content {
    padding: 3em 2em;

    &-ph {
      font-size: 1.4em;
      text-align: center;
      padding: 10em 2em;
      color: $color-grey;
    }

    &_loading {
      @include loading-fog();
    }
  }
}
</style>
<i18n>
{
  "en": {
    "LEADS_CREATED": "LEADS CREATED",
    "LEADS_TO_ON_HOLD": "TO ON-HOLD",
    "LEADS_TO_ARCHIVE": "TO ARCHIVE",
    "LEADS_FROM_ON_HOLD": "LEADS FROM ON-HOLD",
    "LEADS_FROM_ARCHIVE": "LEADS FROM ARCHIVE",
    "LEADS_ASSIGNED": "ASSIGNED TO BROKERS",
    "LEADS_CONVERSION_SUMMARY": "CONVERSION",
    "LEADS_ASSIGNED_PH": "No leads assigned",
    "RESET_BTN": "Reset",
    "GROUP_BY": "GROUP BY",
    "PERIOD_DAY": "DAY",
    "PERIOD_WEEK": "WEEK",
    "PERIOD_MONTH": "MONTH",
    "NO_DATA_TO_DISPLAY": "No data for this period"
  },
  "ka": {
    "LEADS_CREATED": "ლიდი შექმნილია",
    "LEADS_TO_ON_HOLD": "გადავიდა ოდესმეში",
    "LEADS_TO_ARCHIVE": "გადავიდა არქივში",
    "LEADS_FROM_ON_HOLD": "ლიდები ოდესმედან",
    "LEADS_FROM_ARCHIVE": "ლიდები არქივიდან",
    "LEADS_ASSIGNED": "მიემაგრა ბროკერს",
    "LEADS_CONVERSION_SUMMARY": "კონვერსია",
    "LEADS_ASSIGNED_PH": "ლიდი არ მიმაგრებულა",
    "RESET_BTN": "გაწმენდა",
    "GROUP_BY": "დაჯგუფება",
    "PERIOD_DAY": "დღე",
    "PERIOD_WEEK": "კვირა",
    "PERIOD_MONTH": "თვე",
    "NO_DATA_TO_DISPLAY": "ამ პერიოდზე მონაცემი ვერ მოიძებნა"
  },
  "ru": {
    "LEADS_CREATED": "ЛИДОВ СОЗДАНО",
    "LEADS_TO_ON_HOLD": "В ОЖИДАНИИ",
    "LEADS_TO_ARCHIVE": "ПЕРЕНЕСЕНО В АРХИВ",
    "LEADS_FROM_ON_HOLD": "ЛИДЫ ИЗ ОЖИДАНИЯ",
    "LEADS_FROM_ARCHIVE": "ЛИДЫ ИЗ АРХИВА",
    "LEADS_ASSIGNED": "НАЗНАЧЕНО НА БРОКЕРОВ",
    "LEADS_CONVERSION_SUMMARY": "КОНВЕРСИЯ",
    "LEADS_ASSIGNED_PH": "Нет назначеных лидов",
    "RESET_BTN": "Сбросить",
    "GROUP_BY": "ГРУППИРОВАТЬ ПО",
    "PERIOD_DAY": "ДНЯМ",
    "PERIOD_WEEK": "НЕДЕЛЯМ",
    "PERIOD_MONTH": "МЕСЯЦАМ",
    "NO_DATA_TO_DISPLAY": "Данные за этот период отсутствуют"
  },
  "uk": {
    "LEADS_CREATED": "ЛІДІВ СТВОРЕНО",
    "LEADS_TO_ON_HOLD": "НА ОЧІКУВАННІ",
    "LEADS_TO_ARCHIVE": "У АРХІВ",
    "LEADS_FROM_ON_HOLD": "ЛІДИ З ОЧІКУВАННЯ",
    "LEADS_FROM_ARCHIVE": "ЛІДИ З АРХІВУ",
    "LEADS_ASSIGNED": "ПРИЗНАЧЕНО НА БРОКЕРІВ",
    "LEADS_CONVERSION_SUMMARY": "КОНВЕРСIЯ",
    "LEADS_ASSIGNED_PH": "Не призначено",
    "RESET_BTN": "Скинути",
    "GROUP_BY": "ГРУПУВАННЯ ПО",
    "PERIOD_DAY": "ДНЯМ",
    "PERIOD_WEEK": "ТИЖНЯМ",
    "PERIOD_MONTH": "МІСЯЦЯМ",
    "NO_DATA_TO_DISPLAY": "Дані за цей період відсутні"
  }
}
</i18n>
