import { ModelBase } from 'Models/ModelBase'
import { Lead } from 'Models/Lead'
import get from 'lodash/get'
import set from 'lodash/set'
import isEmpty from 'lodash/isEmpty'

export class ReportLeadsStatistics extends ModelBase {
  constructor (raw = {}) {
    super(raw)

    this.id = this._str(raw.id)
    this.from = this._date(raw.from)
    this.to = this._date(raw.to)
    this.groupByPeriod = this._str(raw.groupByPeriod)

    const report = {}
    const data = raw.relationships

    const simpleCounters = [
      'leadsCreated',
      'leadsToArchive',
      'leadsToOnHold',
      'leadsToOnHoldWithoutBroker',
      'leadsFromOnHold',
      'leadsFromArchive'
    ]

    simpleCounters.forEach(field => {
      const entries = get(data, `${field}.entries`, [])
      if (!isEmpty(entries)) {
        entries.forEach(entry => {
          this._ensureTimePoint(report, entry.timePoint)
          set(report, `${entry.timePoint}.${field}.total`, entry.count)
        })
      }
    })

    const leadsCreatedPerChannel = get(data, 'leadsCreatedPerChannel', [])
    if (!isEmpty(leadsCreatedPerChannel)) {
      leadsCreatedPerChannel.forEach(c => {
        c.entries.forEach(entry => {
          this._ensureTimePoint(report, entry.timePoint)
          set(
            report,
            `${entry.timePoint}.leadsCreated.perChannel.${c.channel}`,
            entry.count
          )
        })
      })
    }

    get(data, 'leadsToAssignedPerBorker', []).forEach(broker => {
      broker.entries.forEach(entry => {
        this._ensureTimePoint(report, entry.timePoint)
        report[entry.timePoint].leadsToAssignedPerBorker.push({
          brokerId: get(broker, 'relationships.identity.id'),
          avatarUrl: get(broker, 'relationships.identity.relationships.basic.avatarLink'),
          fullName: get(broker, 'relationships.identity.relationships.basic.fullName'),
          count: entry.count
        })
      })
    })

    this.computeAdditionalMetrics(report)

    this.report = Object.values(report).sort((a, b) => b.date - a.date)
  }

  computeAdditionalMetrics (report) {
    for (let timePoint in report) {
      const chunk = report[timePoint]
      const summary = chunk.leadConversionSummary

      chunk.leadsToAssignedPerBorker.forEach(b => {
        summary.assigned += b.count
      })

      summary.incoming =
        chunk.leadsCreated.total +
        chunk.leadsFromOnHold.total +
        chunk.leadsFromArchive.total

      summary.skipped =
        chunk.leadsToArchive.total + chunk.leadsToOnHold.total

      if (summary.assigned && summary.incoming) {
        summary.value = summary.assigned / summary.incoming
        summary.percent =
          (summary.assigned / summary.incoming * 100).toFixed(1) + '%'
      }
    }
  }

  static get periodEnums () {
    return {
      DAY: 'day',
      WEEK: 'week',
      MONTH: 'month',
    }
  }

  _ensureTimePoint (report, rawDate) {
    if (!report[rawDate]) {
      report[rawDate] = {
        date: this._date(rawDate),
        rawDate: rawDate,
        leadsCreated: {
          total: 0,
          perChannel: {
            [Lead.channelsEnum.phone]: 0,
            [Lead.channelsEnum.email]: 0,
            [Lead.channelsEnum.website]: 0,
            [Lead.channelsEnum.carfax]: 0,
            [Lead.channelsEnum.facebook]: 0,
            [Lead.channelsEnum.telegram]: 0,
            [Lead.channelsEnum.facebookWeb]: 0,
            [Lead.channelsEnum.rajdeba]: 0,
          }
        },
        leadsToOnHold: { total: 0 },
        leadsToOnHoldWithoutBroker: { total: 0 },
        leadsToArchive: { total: 0 },
        leadsFromOnHold: { total: 0 },
        leadsFromArchive: { total: 0 },
        leadsToAssignedPerBorker: [],
        // computed
        leadConversionSummary: {
          incoming: 0,
          skipped: 0,
          assigned: 0,
          value: 0,
          percent: '0%',
        },
      }
    }
  }
}
